.card-availability{
    display: inline-block;
    position: relative;
    font-size: .875rem;
    font-weight: 700;
    color: #f42a3d;

    &:before{
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: #f42a3d;
        margin-top: -1px;
    }

    &.avail{
        color: #04c180;

        &:before{
            background-color: #04c180;
        }
    }
}

.sortable-column{
    cursor: pointer;
}

.table-card-img{
    max-width: 100%;
    height: auto;
}

.table-card-name{
    font-size: .875rem;
    font-weight: 600;
    color: #223345;
}

.table-card-bullets{
    ul{
        diplay: block;
        padding: 0;
        margin: 0;
        font-size: .75rem;
        color: #555;
        list-style: none;

        li{
            font-weight: 400;
            font-size: .75rem;
            color: #555;
        }
    }
}

.table-showing{
    display: flex;
    width: 40%;
    align-items: center;

    select{
        margin-right: 24px;
        width: 100px;
    }
}

.table-paging{
    display: none;
    justify-content: space-between;
    padding: 12px 0;
    align-items: center;

    &.show{
        display: flex;
    }
}

.card-img{
    max-width: 100%;
    height: auto;
    margin-bottom: 32px;
}


.affiliate {

    button {
        display: inline-block;
        position: relative;
        padding: 0px 5px;
        /* margin: 15px; */
        border: 0;
        border-radius: 6px;
        cursor: pointer;
        transition: all .4s 0s ease;
    }
    button,button:before, button:after {
        box-sizing: border-box;
    }
    button:hover {
        background-color: #bababa;
    }
    button:hover:before, button:hover:after {
        visibility: visible;
        opacity: 1;
        transition: all 0.4s 0s ease; /** that's the magic **/
    }
    button:before, button:after {
        content: "";
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: all 2.5s 1s ease;
    }
    button:before {
        width: 0;
        height: 0;
        right: -8px;
        border: 8px solid transparent;
        border-right-color: #181818;
    }
    button:after {
        content: attr(aria-label);
        width: 250px;
        left: calc(100% + 8px);
        padding: 6px;
        background-color: #181818;
        color: #fff;
        border-radius: 4px;
        font-size: 14px;
    }
}

.small-desc {
    font-size: 13px;
    color: #888;
}
