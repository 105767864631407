.form-card {
    padding: 80px 40px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(176, 195, 211, 0.16);
    border-radius: 4px;
}

.auth-form {
    max-width: 480px;
}

.form {
    label {
        font-weight: 700;
        font-size: 0.75rem;
        color: #7d9eb5;
        line-height: 18px;
        text-transform: uppercase;
    }

    button {
        font-weight: 700;
    }
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='url'],
select:not([multiple]) {
    border-color: #a6c0d3;
    height: 40px;
}

.form-group {
    margin-bottom: 1.35rem;
    position: relative;
}

.form-note {
    font-size: 0.875rem;
    color: #7d9eb5;
    text-align: center;
    line-height: 18px;
}

.form-actions {
    padding-top: 12px;
}

.select-wrapper {
    width: 100%;

    select {
        width: 100%;
    }
}
