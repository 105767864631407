.report-status{
    display: inline-block;
    position: relative;
    font-size: .875rem;
    font-weight: 700;

    &:before{
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: #f42a3d;
        margin-top: -1px;
    }

    &.paid{
        color: #04c180;

        &:before{
            background-color: #04c180;
        }
    }

    &.draft{
        color: #999;

        &:before{
            background-color: #999;
        }
    }

    &.pending{
        color: #ff9931;

        &:before{
            background-color: #ff9931;
        }
    }

    &.sent{
        color: #3852b8;

        &:before{
            background-color: #3852b8;
        }
    }

}

.table{
    .dropdown{
        display: inline-block;
    }
}

.dropdown{
    display: inline-block;
}

.has-card-stats{
    .card-sm{
        min-height: 120px;
    }
}

.table-total{

    span{
        font-size: 1.25rem;
        font-weight: 700;

        &:first-child{
            margin-right: 12px;
        }

        &:last-child{
            margin-right: 32px;
        }
    }
}
