/* Responsive styles */
/* // X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .content h1 {
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .num {
        font-size: 25px;
    }
    .membership-content span br {
        display: none;
    }
    .line {
        margin: 10px 0 10px 0;
        height: 85px;
    }
    .card-img {
        display: none;
    }
    .content h1 br {
        display: none;
    }
    .card-img1 img {
        width: 100%;
        height: 155px;
        object-fit: contain;
    }
    .read {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .sub-content {
        display: flex;
        flex-direction: column;
    }

    .content {
        margin: 0px 10px 5px 15px;
    }
    .line a {
        font-size: 14px;
        margin-left: 0;
    }
    .bg {
        padding: 18px 10px 22px;
    }
    .membership-content {
        margin: 0px;
    }

    .line {
        border-left: 0;
        margin: 10px 0 10px 0;
        flex-shrink: 0;
        height: 55px;
    }
    .read {
        margin-bottom: 0;
        margin-top: 0px;
    }
    .membership-content h5 {
        font-size: 14px;
    }
    .line span {
        margin-right: 5px;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .content h1 {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .card-img1 {
        display: none;
    }
    .num {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .membership-content span br {
        display: none;
    }
    .line {
        margin: 10px 0 10px 0;
        height: 85px;
    }

    .card-img img {
        margin-top: 40px;
        width: 190px;
        height: 95px;
    }
    .read {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .content {
        margin: 0px 10px 5px 15px;
    }
    .sub-content {
        flex-direction: column;
    }
    .card-img {
        display: none;
    }
    .card-img1 {
        display: block;
    }
    .line a {
        font-size: 14px;
        margin-left: 0;
    }
    .bg {
        padding: 18px 10px 22px;
    }
    .membership-content {
        margin: 0;
    }

    .line {
        margin: 0;
        flex-shrink: 0;
        height: 85px;
        border-left: 0;
    }
    .read {
        margin-bottom: 0;
        margin-top: 25px;
    }
    .membership-content h5 {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .line span {
        margin-right: 5px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .content h1 {
        font-size: 17px;
        line-height: 24px;
    }
    .card-img1 {
        display: none;
    }
    .num {
        font-size: 24px;
    }
    .membership-content span br {
        display: none;
    }

    .line {
        margin: 10px 0 10px 0;
        height: 85px;
    }

    .card-img img {
        margin-top: 40px;
        width: 190px;
        height: 95px;
    }
    .read {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .content {
        margin: 0px 10px 5px 15px;
    }
    .line a {
        font-size: 16px;
    }
    .bg {
        padding: 18px 10px 22px;
    }
    .membership-content {
        margin: 10px;
    }

    .line {
        margin: 12px 0 10px 0;
        flex-shrink: 0;
    }
    .read {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .membership-content h5 {
        font-size: 15px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .content h1 {
        font-size: 20px;
        line-height: 27px;
    }
    .card-img1 {
        display: none;
    }
    .num {
        font-size: 35px;
    }
    .line a {
        font-size: 16px;
    }
    .membership-content {
        margin: 15px;
    }
    .line {
        margin: 12px 0 10px 0;
    }
    .line a {
        font-size: 15px;
    }
    .read {
        margin-bottom: 0;
        margin-top: 10px;
    }
    .membership-content h5 {
        font-size: 15px;
    }
    .card-img img {
        width: 138px;
        height: 85px;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400.98px) {
    .card-img1 {
        display: none;
    }
}

/* // For 2k Monitors, (more than 1401 px) */
@media (min-width: 1401px) and (max-width: 1599.98px) {
    .card-img1 {
        display: none;
    }
}

@media (min-width: 1600px) and (max-width: 2559.98px) {
    .card-img1 {
        display: none;
    }
}

@media (min-width: 2560px) {
}
