@media(max-width: 767px){

    .dashboard-nav{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
    }

    .close-mobile-nav{
        display: block;
    }

    .mobile-bottom-margin{
        margin-bottom: 32px;
    }

    .cards-group.three-col .card-sm,
    .cards-group .card-sm{
        width: 100%;
        flex-basis: auto;
    }

    .table-paging{
        flex-wrap: wrap;
    }

    .table-showing{
        margin-bottom: 24px;
    }

    .table-showing,
    .table-pagination{
        width: 100%;
    }
}
