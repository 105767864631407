.hide,
[v-cloak] {
    display: none;
}

.label-muted {
    color: $label-muted;
}

.line-through {
    text-decoration: line-through;
}
