
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family:  "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #4C5E68;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Montserrat", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none !important;
  outline: none !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:focus,
a:hover {
  text-decoration: none;
}

.container{
  max-width: 1060px;
  margin: auto;
}

.card-img {
  width: 200px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}


.card-img img {
  max-width: 100%;
  height: auto;
}


.bg{
  background:#F4F7FA;
  padding:20px 35px 25px;
}
.full-img{
  display:flex;
  justify-content: center;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content h1{
  font-size: 23px;
  line-height: 29px;
  margin:0;
  color:#2F434E;
}
.sub-content {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.left-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.left-content .num {
 font-size: 40px;
  color:#EC8154;
  font-weight: bold;
  margin-top: 35px;

}

.vertical-line {
  border-left: 1px solid #BBD2E1;
  margin: 0 20px;
  height: 75px;
}

.right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.membership-content{
 margin: 20px;
}
.membership-content h5{
  margin:0px;
  font-size: 18px;
  color:#2F434E;
}
.membership-content span{
  margin:0px;
  font-weight:600;
  color:#4C5E68;
  line-height: 19px;
}
.line {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.line a {
  display: flex;
  align-items: center;
  margin-right: 20px;
}


.read{
  margin-bottom: 15px;
}
.terms {
  margin-top: 15px;
  text-align: center;
}
.terms span{
  color:#EC8154;
  font-size: 12px;

}
.terms a{
 color: #4C5E68;
 font-size: 12px;
 text-align: center;
 display: flex;
 justify-content: center;
}
.line span{
  margin-right: 10px;
}

#cardDisplayOption{
  background-color: #ffffff;
}

.far-btn{
    margin-top: 4px;
    float: right;
}
