.messages{

    .alert{
        border-radius: none;
    }

    ul{
        display: block;
        padding: 0;
        margin: 0;
        list-style: none;
    }
}
