html {
    scroll-behavior: smooth;
}

body{
    font-size: 1rem;
    background-color: $body-bg;
    color: $body-font-color;
}

h1,h2,h3,h4,h5,h6{
    font-weight: 700;
    color: $midnight;
    margin-bottom: 1.5rem;

    span{
        display: inline-block;
        vertical-align: middle;
    }

    .btn{
        margin-left: 12px;
        margin-top: -2px;
    }
}

h1{
    font-size: 2.25rem;
}

h2{
    font-size: 2rem;
}

h3{
    font-size: 1.875;
}

h4{
    font-size: 1.5rem;
}

h5{
    font-size: 1.375rem;
}

h6{
    font-size: 1.125rem;
}

p{
    margin-bottom: 1.5rem;
}

a{
    color: $link;
    transition: color .25s ease;

    &:hover{
        text-decoration: none;
        color: lighten($link, 15%);
    }
}

