#toast.toast{
    background-color: rgba(#28a745,.85);
    color: #fff;
    font-weight: 700;
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 999999;

    font-size: 16px;

    .toast-body {
        padding: 1.25rem;
    }
}
