.dashboard-header{
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    box-shadow: 0 2px 10px 0 rgba(133,157,177,0.10);

    a{
        color: #222;
        font-size: .875rem;
        font-weight: 500;
        transition: color .25s ease;

        &:hover{
            color: #777;
        }
    }
}

.dashboard-nav-toggler{
    i{
        margin-right: 16px;
    }
}

.dashboard-header-brand{
    display: flex;
    align-items: center;
}

.dashboard-header-nav{
    flex-grow: 1;
    display: flex;
    align-items: center;

    .navbar-nav{
        flex-direction: row;
        align-items: center;
    }

    .settings-link{
        padding: 0 12px;

        a{
            font-size: 1.25rem;
        }
    }
}
