.dashboard-nav{
    width: 264px;
    min-height: 100vh;
    background-color: $sidebar;
    padding: 24px 0;
    transition: transform .12s ease-in-out;
    transform: translateX(-270px);

    .inner-for-toggle{
        position: relative;
    }

    /*&.collapse:not(.show){
        display: block !important;
    }*/

    &.show{
        transform: translateX(0);
    }

    .inner-nav-scroll{
        overflow-y: auto;
        position: relative;
    }

    ul{
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            position: relative;

            a, .a-link{
                display: block;
                position: relative;
                padding: 8px 24px;
                font-size: .875rem;
                font-weight: 400;
                color: #9ea7bb;
                transition: color .25s ease, background-color .25s ease;
                cursor: pointer;

                i{
                    margin-right: 16px;
                    font-size: 1.15rem;
                    vertical-align: middle;
                    margin-top: -4px;
                }

                &:hover{
                    background-color: #0f2e69;
                }

                &.active{
                    color: #fff;
                    background-color: #0f2e69;
                }
            }

            .dropdown-arrow{
                position: absolute;
                right: 16px;
                top: 14px;
                pointer-events: none;
                transition: transform .25s ease;
            }

            ul{
                display: none;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    a{
                        padding-left: 62px;

                        &.active{
                            color: #fff;
                            //background-color: lighten(#0f2e69, 5%);
                        }
                    }
                }
            }

            &.has-dropdown{

                &.open{
                    .dropdown-arrow{
                        transform: rotate(90deg);
                    }

                    .a-link{
                        color: #fff;
                    }

                    ul{
                        display: block;
                    }
                }

            }


        }
    }
}

.sidebar-logo{
    text-align: center;
    margin-bottom: 32px;

    img{
        max-width: 70%;
        height: auto;
        margin: 0 auto;
        vertical-align: bottom;
    }
}

.close-mobile-nav{
    display: none;
    width: 32px;
    height: 32px;
    font-size: 26px;
    color: #111;
    position: absolute;
    right: -48px;
    top: -6px;

    &:hover{
        text-decoration: none;
        color: #777;
    }
}
.message-notification {
    background: #05a57d;
    padding: 0px 11px;
    border-radius: 25px;
    color: #fff;
    font-weight: 700;
    margin-left: auto;
    float: right;
    font-size: 13px;
}
.dash-board-notification-bg{
    background: #3852b8;
}
