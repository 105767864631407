.faqs-list{
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 4px;
    overflow: hidden;

    li {
        display: block;
        margin-bottom: 2px;

        a{
            display: block;
            padding: 6px 12px;
            color: #444;
            background-color: #ddd;
            transition: background-color .25s ease;

            &:hover{
                background-color: #f4f4f4;
            }
        }
    }
}

.faq-section{
    padding-top: 32px;
}
