.breadcrumb{
    background-color: transparent;
    padding: 0 1rem;

    color: $midnight;

    .breadcrumb-item{
        color: $link;
        font-weight: 500;
        font-size: .875rem;

        &.active{
            color: $midnight;
            font-weight: 400;
        }
    }
}

.breadcrumb-item + .breadcrumb-item::before{
    display: inline-block;
    margin: 2px 3px 0 3px;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    color: $midnight;
}
