.element-logo{
    max-width: 295px;
    svg{
        max-width: 100%;
        height: auto;
    }
}

.loading{
    visibility: hidden;
    width: 200px;
    padding: 32px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    opacity: 0;
    border-radius: 4px;
    background-color: rgba(000,000,000, .85);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .7s ease;
    box-shadow: none;
    z-index: 999999;

    &.show-loading{
        visibility: visible;
        box-shadow:
          0 2.8px 2.2px rgba(0, 0, 0, 0.02),
          0 6.7px 5.3px rgba(0, 0, 0, 0.028),
          0 12.5px 10px rgba(0, 0, 0, 0.035),
          0 22.3px 17.9px rgba(0, 0, 0, 0.042),
          0 41.8px 33.4px rgba(0, 0, 0, 0.05),
          0 100px 80px rgba(0, 0, 0, 0.07)
        ;
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

 // For Notification Feature
 
// .fa-stack[data-count]:after {
//     position: absolute;
//     right: -10px;
//     top: -12px;
//     content: attr(data-count);
//     font-size: 12px;
//     padding: 6px;
//     border-radius: 999px;
//     line-height: 9px;
//     color: white;
//     background: rgba(255, 0, 0, 0.85);
//     text-align: center;
//     min-width: 22px;
//     font-weight: bold;
// }
