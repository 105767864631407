// Body
$body-bg: #f3f7f9;
$body-font-color: #223345;

// Typography
$font-family-sans-serif: 'Inter', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='353b3c' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z'%3E%3C/path%3E%3C/svg%3E");

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Brand colors
$darkblue: #26397d;
$aquablue: #07a7d3;
$midnight: #213040;

$link: #11c9f9;
$sidebar: #0C2556;
$label-muted: #9ab4c6;

$table-header-font-color: #7D9EB5;
$input-placeholder-color: #7d9eb5;
$export-btn-bgcolor: #e3eef6;
