.header{

}

.navbar-brand{
    min-width: 200px;

    transition: opacity .25s ease;

    .logo{
        max-width: 200px;
        height: auto;
        vertical-align: bottom;
    }
    span{
        display: none;
    }

    &:hover{
        opacity: .45;
    }
}
